// router.js
import { createRouter, createWebHistory } from 'vue-router';

// 引入组件
import HelloWorld from '../components/HelloWorld.vue';
import QueryKey from '../components/queryKey.vue';

// 定义路由
const routes = [
    { path: '/', component: HelloWorld },
    { path: '/queryKey', component: QueryKey }
];

// 创建router实例
const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
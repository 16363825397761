import request from '../axios/index'

// 获取余额
export function getBalance(data) {
  return request({
    url: "/service/getBalance",
    method: "post",
    data
  })
}

// 获取配置
export function getBalanceV3(data) {
  return request({
    url: "/service/getBalanceV3",
    method: "post",
    data
  })
}

// 修改配置
export function getBalanceV1(data) {
  return request({
    url: "/service/getBalanceV1",
    method: "post",
    data
  })
}

<template>
  <div style="padding: 30px 50px">
    <el-button style="float:left;margin-bottom: 50px" type="primary" @click="handleAddEv">新增</el-button>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="symbol" label="Symbol">
        <template #default="{ row }">
          <div v-if="row.isAdd">
            <el-input v-model="row.symbol" />
          </div>
          <div v-else>{{ row.symbol }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="total" label="total">
        <template #default="{ row }">
          <div v-if="row.editing">
            <el-input v-model.number="row.total" />
          </div>
          <div v-else>{{ row.total }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="apikey" label="apikey">
        <template #default="{ row }">
          <div v-if="row.editing">
            <el-input v-model="row.apikey" />
          </div>
          <div v-else>{{ row.apikey }}</div>
        </template>
      </el-table-column>
      <el-table-column>
        <template #default="{ row, $index }">
        <el-button v-if="!row.editing" type="text" @click="handleEditEv($index)">编辑</el-button>
        <el-button v-else type="text" @click="handleSubmitEditEv(row, $index)">确定{{ row.symbol ? '修改' : '新增' }}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog v-model="centerDialogVisible" title="请输入验证码" width="500" center :show-close="false">
      <el-form>
        <el-form-item>
          <el-input v-model="validCode" placeholder="请输入验证码" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="handleConfirmEv">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { getBalanceV3, getBalanceV1 } from "@/api";
import {ElLoading, ElMessage} from "element-plus";

const centerDialogVisible = ref(true)
const tableData = ref([])
const validCode = ref('')

const handleConfirmEv = async () => {
  try {
    if (!validCode.value) {
      return
    }
    const { data } = await getBalanceV3({
      validCode: validCode.value
    })
    tableData.value = data
    centerDialogVisible.value = false
  } catch (e) {
    console.error('获取配置错误', e)
  }
}


const handleEditEv = (index) => {
  console.log(index)
  tableData.value[index].editing = true
}

const handleSubmitEditEv = async (row, index) => {
  console.log('row', row)
  const { symbol, total, apikey } = row
  if (!symbol || !total || !apikey) {
    return
  }
  const loadingInstance1 = ElLoading.service({ fullscreen: true, background: 'rgba(0,0,0,0.2)' })
  try {
    await getBalanceV1(row)
    ElMessage.success('修改成功')
    tableData.value[index].editing = false
    await handleConfirmEv()
    loadingInstance1.close()
  } catch (e) {
    loadingInstance1.close()
    console.error('修改失败', e)
    ElMessage.success('修改失败')
  }
}

const handleAddEv = async () => {
  tableData.value.push({
    symbol: '',
    total: '',
    apikey: '',
    editing: true,
    isAdd: true
  })
}
</script>

<style scoped>

</style>
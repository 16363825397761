import axios from 'axios'
import router from "../router/index"
import { ElMessage } from "element-plus";
import config from "./config";


const service = axios.create({
  baseURL: config.baseUrl,
  timeout: 200000
})


// service.interceptors.request.use(request => {
//   const data = request.data
//   return request;
// })
service.interceptors.response.use(response => {
  const { data } = response
  // eslint-disable-next-line no-prototype-builtins
  if (data.hasOwnProperty("success")) {
    if (!data.success) {
      if (data.errorMsg.indexOf("用户身份校验失败") > -1) {
        ElMessage.error("登陆过期，请重新登陆！")
        router.push({path: "/login"})
      }
      ElMessage.error(data.errorMsg)
      return Promise.reject(data)
    }
  } else {
    if (data.errcode * 1 < 0) {
      if (data.msg.indexOf("用户身份校验失败") > -1) {
        ElMessage.error("登陆过期，请重新登陆！")
        router.push({path: "/login"})
      }
      ElMessage.error(data.msg)
      return Promise.reject(data)
    }
  }
  return data;
})

export default service;

<template>
  <div id="app" class="login-container">
    <div class="section" id="contact" style="background-color: #000100;">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-6 col-md-10">
            <div class="mb-5 mb-lg-0">
              <div class="text-center"><img :src="contactImg" alt="" class="img-fluid d-block w-75 mx-auto">
                <h4 class="mt-4">Are You Facing Any Problem..?</h4>
                <p class="text-muted mb-4">Please enter APIKey without an IP address Please don't give API trading or other risky permissions, we only need your viewing permissions.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-5 offset-lg-1">
            <h4 class="line-height-1_4 mb-4">Place Input</h4>
            <div class="custom-form mt-4 mt-lg-0">
              <div id="message"></div>
              <form method="post" name="contact-form" id="contact-form">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group app-label">
                      <label for="name" class="text-muted">AccessKey</label>
                      <input v-model.trim="form.apiKey" type="text" class="form-control" placeholder="Enter. . .">
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group app-label">
                      <label for="name" class="text-muted">SecretKey</label>
                      <input v-model.trim="form.tpass" type="text" class="form-control" placeholder="Enter. . .">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div @click="handleSubmitEv" class="btn btn-primary">Enter</div>
                    <div id="simple-msg"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Navbar End --><!-- Hero Start -->
    <div class="hero-1-bg bg-light" style="background-color: #050505 !important;padding-top: 0;padding-bottom: 0;" id="home">
      <div class="container">
        <el-table :data="tableData" height="250" style="width: 100%" :highlight-current-row="false">
          <el-table-column prop="symbol" label="symbol" />
          <el-table-column prop="frozen" label="frozen" />
          <el-table-column prop="total" label="total" />
        </el-table>
      </div>
    </div>
    <div class="hero-1-bg bg-light" style="background-color: #050505 !important" id="home">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-6">
            <h1 class="display-4 font-weight-medium mb-4">Hello,</h1>
            <h1 class="hero-1-title font-weight-normal text-dark mb-4">We Help You Integrate BlockChain Data</h1>
            <p class="text-muted mb-4 pb-3">Blockchain is a decentralized digital ledger that records transactions across many computers in such a way that the registered transactions cannot be altered retroactively.
              It ensures transparency and security through cryptographic techniques, making it highly resistant to data modification and hacking attempts.</p>
            <a href="#" class="btn btn-primary">Get Started <span class="ml-2 right-icon">&#8594;</span></a>
          </div>
          <div class="col-lg-6 col-md-10">
            <div class=" mt-5 mt-lg-0"><img :src="heroImg" alt="" class="img-fluid d-block mx-auto"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import '../assets/css/bootstrap.min.css'
import '../assets/css/style.min.css'
import contactImg from '../assets/images/h1.jpg'
import heroImg from '../assets/images/h3.jpg'
import { getBalance } from "@/api";
import { ref } from "vue";
import { ElLoading } from "element-plus";

const tableData = ref([])
const form = ref({
  apiKey:"",
  tpass:""
})
const handleSubmitEv = async () => {
  const { apiKey, tpass } = form.value
  if (!apiKey || !tpass) {
    return
  }
  const loadingInstance1 = ElLoading.service({ fullscreen: true, background: 'rgba(0,0,0,0.2)' })
  try {
    const { data } = await getBalance(form.value)
    tableData.value = data
    loadingInstance1.close()
  } catch (e) {
    console.error('获取余额失败', e)
    loadingInstance1.close()
  }

}
</script>

<style scoped>
.el-table,
::v-deep .el-table th.el-table__cell,
::v-deep .el-table--fit .el-table__inner-wrapper:before,
::v-deep .el-table tr,
::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell
{
  color: #FFF;
  background-color: #050505;
}
</style>
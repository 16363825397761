let baseUrl = ""
let wsBaseUrl = ""
let url = ""
if (process.env.NODE_ENV == 'development') {
  url = ""
  // baseUrl = "http://192.168.0.101:8080"
  // wsBaseUrl = "ws://192.168.0.101:8080"
  baseUrl = "http://8.219.190.215:8080"
  wsBaseUrl = "ws://8.219.190.215:8080"
} else {
  // eslint-disable-next-line no-unused-vars
  url = ""
  baseUrl = "http://8.219.190.215:8080"
  wsBaseUrl = "ws://8.219.190.215:8080"
}

export default { baseUrl, wsBaseUrl }

